import React from 'react'
import "./Bannerdm.css";
import { Helmet } from 'react-helmet';
function Bannerdm() {
  return (
<>
<Helmet>
        <title>Complete Digital Marketing Solutions in Coimbatore | SEO, SMM & Ads</title> 
        <meta name="description" content="Achieve your business goals with expert digital marketing services in Coimbatore. We specialize in SEO, Google Ads, Social Media Marketing, and tailored strategies for B2B success. Get started today to see results!." />
 <meta name="keywords" content="digital marketing services, SEO optimization, social media marketing, Google Ads management, digital marketing agency website, online marketing, digital marketing strategy, B2B digital marketing, internet marketing service near me, website SEO optimization, creative digital marketing, social media ad campaigns, search engine optimization marketing, top search engines, marketing through social media, Coimbatore marketing firms, social media digital marketing, digital marketing and advertising, social media agency services, media and marketing, digital marketing service coimbatore" />
<link rel="canonical" href="https://www.kggeniuslabs.com/digital-marketing-services-coimbatore" />
      </Helmet>
    <div className='container-fluid bannerdmbg py-5 d-none d-md-block'>
      <div className='row'>
      <div className='text-light py-5 px-0 px-md-2 px-lg-5 mx-0 mx-lg-5'>
        <div className='col-sm-12 col-lg-6'>
       <h1 className='bannerdmhead'>Your Partner in Digital Success</h1>
       <h5 className='bannerparadm'>Expert Marketing Services to Amplify Reach, Build Trust, and Boost Sales</h5>
       <h3 className='bannerparadm1'>Best Digital Marketing Service in Coimbatore</h3>
       <button className='bg-light rounded-2 border-0 px-4 py-3 my-2 connectbtn'>Get a Free Website Audit</button>
       </div>
      </div>
      </div>
      </div>

      <div className='container-fluid bannerdmbgmb pt-5 d-block d-md-none text-light text-center'>
   <div className='d-flex flex-column justify-content-end pt-5 px-2'>
       <h1 className='bannerdmhead mt-5 pt-5'>Your Partner in Digital Success</h1>
       <h5 className='bannerparadm'>Expert Marketing Services to Amplify Reach, Build Trust, and Boost Sales.</h5>
       <h3 className='bannerparadm1 py-2'>Best Digital Marketing Service in Coimbatore</h3>
       <div className='d-flex justify-content-center'>
       <button className='bg-light rounded-5 border-0 px-2 py-1 my-2 connectbtn'>Get a Free Website Audit</button>
       </div>
      </div>
      </div>
     
      </>
  )
}

export default Bannerdm